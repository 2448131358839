import axios from 'axios';
import request from './request';
import objConfig  from './config';
import local  from './local';


var FormData = require('form-data');

var configs = {}



var path = {
    //login
    /*login: '/api/user/login',
    resetPass: '/api/user/resetPass',
    createNewPass: '/api/user/createNewPass',
    resetPassVerifyOTP: '/api/user/resetPassVerifyOTP',*/

    // thirdPartyLogin: '/api/user/thirdPartyLogin',
    login: '/api/merchant/login',
    verifyLogin: '/api/merchant/verifyLogin',

    logout: '/api/merchant/logout',
    getCaptcha: '/api/user/create-captcha',
    getMeta: '/api/admin/get-meta',
    getLanguage: '/api/language/getLanguage',



    merchantBalance: '/api/merchant/merchantBalance',
    
    merchantPoint: '/api/loyalty/getPoint',
    earnPointHistory: "/api/loyalty/earnPointHistory",
    exportEarnPointHistory: "/api/loyalty/exportEarnPointHistory",
    settlementPointHistory: "/api/loyalty/settlementPointHistory",
    exportSettlementPointHistory: "/api/loyalty/exportSettlementPointHistory",
    settlementPoint: "/api/loyalty/settlementPoint",

    merchantSaleReport: '/api/transaction/merchantSaleReport',
    shopSaleReport: '/api/transaction/shopSaleReport',
    topSellingSubmerchant: '/api/transaction/topSellingSubmerchant',
    transactionByService: '/api/report/transactionByService',

    findTransactions: '/api/report/findTransactions',
    exportTransactions: '/api/report/exportTransactions',
    detailTransaction: '/api/report/detailTransaction',
    findServiceList: '/api/report/findServiceList',
    findShopList: '/api/report/findShopList',
    findSubmerchantList: '/api/submerchant/findSubmerchantList',


    findShops: '/api/report/findShops',
    exportShops: '/api/report/exportShops',
    getShopDetail: '/api/shop/shopDetail',

    getProvince: '/api/province/getProvince',
    getTownship: '/api/township/getTownship',
    getCountry: '/api/country/getCountry',
    uploadImage: '/api/upload/uploadImage',

    createShop: '/api/shop/createShop',
    updateShop: '/api/shop/updateShop',
    lockShop: '/api/shop/lockShop',
    activeShop: '/api/shop/activeShop',
    shopList: '/api/shop/shopList',


    findSubmerchants: '/api/report/findSubmerchants',
    exportSubMerchant: '/api/report/exportSubMerchant',
    createSubmerchant: '/api/submerchant/createSubmerchant',
    updateSubmerchant: '/api/submerchant/updateSubmerchant',
    lockSubmerchant: '/api/submerchant/lockSubmerchant',
    activeSubmerchant: '/api/submerchant/activeSubmerchant',
    getSubmerchantDetail: '/api/submerchant/submerchantDetail',
    findNrcConfig: '/api/submerchant/findConfig',

    findClientRoles: '/api/clientrole/findClientRoles',
    clientRolesList: '/api/clientrole/clientRolesList',
    createUserRole: '/api/clientrole/createUserRole',
    detailClientRoles: '/api/clientrole/detailClientRoles',
    findPermissions: '/api/permission/findPermissions',
    updateClientRolePerms: '/api/permission/updateClientRolePerms',

    findApiKeys: '/api/api-key/findApiKeys',
    createApiKey: '/api/api-key/createApiKey',
    updateApiKey: '/api/api-key/updateApiKey',
    detailApiKey: '/api/api-key/detailApiKey',
    onlineMerchantApis: '/api/api-key/onlineMerchantApis',
    revokeApiKey: '/api/api-key/revokeApiKey',
    enableApiKey: '/api/api-key/enableApiKey',

    deviceList: '/api/device/deviceList',
    removeDevice: '/api/device/removeDevice',

    changePassword: '/api/merchant/changePassword',
    createDevice: '/api/device/createDevice',
    findChannels: '/api/merchant/findChannels',

    exportedReports: '/api/report/exportedReports',

    requestMoveMoney: '/api/transaction/requestTransaction',
    confirmMoveMoney: '/api/transaction/confirmTransaction',
    verifyMoveMoney: '/api/transaction/verifyTransaction',

    listBankAccountsByUser: '/api/transaction/listBankAccountsByUser',

};



Object.keys(path).forEach(function (key) {
    configs[key] = async function (data, token) {
        if(data && data.isUpload){
            let formData = new FormData()
            let blob = await fetch(data.file).then(r => r.blob());
            formData.append('file', blob);
            let cong = {
              headers: {
                'Authorization': `Bearer ${local.get('session')}`,
                'content-type': 'multipart/form-data'
              }
            }
            let URI = objConfig.host+'/api/upload/uploadImage';
            try{
                let result = await axios.post(URI, formData, cong);
                return result;
            }catch(err){
                return {err: 400, message: err.message};
            }

        } else{
            let result = await request.request(path[key], data, token);
            return result;
        }


    }
}, this);




export default configs;
